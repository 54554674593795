import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';

const EnergeticBlocksBackground = () => {
  const canvasRef = useRef(null);
  const cometTimeoutRef = useRef(null);
  const cometStartTimeRef = useRef(null);
  const cometDelayRef = useRef(null);
  const cometRemainingRef = useRef(null);
  const isFirstCometRef = useRef(true);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    setCanvasSize();

    const stars = [];
    const numStars = 75;

    const waves = [];
    const numWaves = 6;
    const comets = [];

    for (let i = 0; i < numStars; i++) {
      stars.push({
        x: Math.random() * canvas.width,
        y: Math.random() * (canvas.height / 2),
        radius: Math.random() * 1.5 + 0.5,
        alpha: Math.random(),
        twinkleSpeed: Math.random() * 0.005 + 0.001,
      });
    }

    for (let i = 0; i < numWaves; i++) {
      waves.push({
        y: canvas.height / 2 - (numWaves / 2 - i) * 20,
        speed: Math.random() * 0.005 + 0.005,
        offset: Math.random() * 360,
        amplitude: Math.random() * 15 + 10,
        color: `hsl(${Math.random() * 360}, 80%, 50%)`,
      });
    }

    const drawStars = () => {
      stars.forEach((star) => {
        star.alpha += star.twinkleSpeed;
        if (star.alpha > 1 || star.alpha < 0) {
          star.twinkleSpeed = -star.twinkleSpeed;
        }

        ctx.beginPath();
        ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(255, 255, 255, ${Math.abs(star.alpha)})`;
        ctx.fill();
      });
    };

    const updateWaves = () => {
      waves.forEach((wave) => {
        wave.offset += wave.speed;
      });
    };

    const drawWaves = () => {
      waves.forEach((wave) => {
        const yOffset = Math.sin(wave.offset) * wave.amplitude;

        ctx.beginPath();
        ctx.moveTo(0, wave.y + yOffset);
        ctx.lineTo(canvas.width, wave.y + yOffset);
        ctx.lineTo(canvas.width, canvas.height);
        ctx.lineTo(0, canvas.height);
        ctx.closePath();

        ctx.fillStyle = wave.color;
        ctx.globalAlpha = 0.7;
        ctx.fill();
      });
    };

    const scheduleComet = (delay = null) => {
      let cometDelay;
      if (delay !== null) {
        cometDelay = delay;
      } else {
        cometDelay = Math.random() * (120000 - 30000) + 30000;
      }
      cometDelayRef.current = cometDelay;
      cometStartTimeRef.current = Date.now();

      cometTimeoutRef.current = setTimeout(() => {
        const startX = Math.random() * canvas.width;
        comets.push({
          x: startX,
          y: 0,
          speedX: Math.random() * 1.5 + 1,
          speedY: Math.random() * 1 + 0.5,
          length: Math.random() * 80 + 50,
          alpha: 1,
        });
        isFirstCometRef.current = false;
        scheduleComet();
      }, cometDelay);
    };

    const drawComets = () => {
      for (let i = comets.length - 1; i >= 0; i--) {
        const comet = comets[i];
        comet.x += comet.speedX;
        comet.y += comet.speedY;
        comet.alpha -= 0.002;

        ctx.beginPath();
        const gradient = ctx.createLinearGradient(
          comet.x,
          comet.y,
          comet.x - comet.speedX * comet.length,
          comet.y - comet.speedY * comet.length
        );
        gradient.addColorStop(0, `rgba(255, 255, 255, ${comet.alpha})`);
        gradient.addColorStop(1, `rgba(255, 255, 255, 0)`);
        ctx.strokeStyle = gradient;
        ctx.lineWidth = 2;
        ctx.moveTo(comet.x, comet.y);
        ctx.lineTo(
          comet.x - comet.speedX * comet.length,
          comet.y - comet.speedY * comet.length
        );
        ctx.stroke();

        if (
          comet.alpha <= 0 ||
          comet.y - comet.speedY * comet.length > canvas.height ||
          comet.x - comet.speedX * comet.length > canvas.width
        ) {
          comets.splice(i, 1);
        }
      }
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawStars();
      updateWaves();
      drawWaves();
      drawComets();
      requestAnimationFrame(animate);
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (cometRemainingRef.current !== null) {
          cometTimeoutRef.current = setTimeout(() => {
            const startX = Math.random() * canvas.width;
            comets.push({
              x: startX,
              y: 0,
              speedX: Math.random() * 1.5 + 1,
              speedY: Math.random() * 1 + 0.5,
              length: Math.random() * 80 + 50,
              alpha: 1,
            });
            scheduleComet();
          }, cometRemainingRef.current);
          cometStartTimeRef.current = Date.now();
          cometDelayRef.current = cometRemainingRef.current;
          cometRemainingRef.current = null;
        }
      } else {
        if (cometTimeoutRef.current) {
          const elapsed = Date.now() - cometStartTimeRef.current;
          const remaining = cometDelayRef.current - elapsed;
          if (remaining > 0) {
            cometRemainingRef.current = remaining;
          }
          clearTimeout(cometTimeoutRef.current);
          cometTimeoutRef.current = null;
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    animate();
    const initialDelay = Math.random() * (4000 - 2000) + 2000;
    scheduleComet(initialDelay);

    const preventScroll = (e) => {
      if (e.target === canvas) {
        e.preventDefault();
      }
    };

    window.addEventListener('scroll', preventScroll, { passive: false });

    return () => {
      clearTimeout(cometTimeoutRef.current);
      window.removeEventListener('scroll', preventScroll);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'radial-gradient(circle, #222222, #202020, #242424)',
        zIndex: -1,
        overflow: 'hidden',
        touchAction: 'none',
      }}
    >
      <canvas
        ref={canvasRef}
        style={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
          willChange: 'transform',
          pointerEvents: 'none',
        }}
      />
    </Box>
  );
};

export default EnergeticBlocksBackground;
