// frontend/src/index.js

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppProvider } from './context/AppContext';
import { ThemeProvider, CssBaseline, GlobalStyles } from '@mui/material';
import getTheme from './theme';

const container = document.getElementById('root');
const root = createRoot(container);

const RootComponent = () => {
  return (
    <React.StrictMode>
      <AppProvider>
        <ThemeProvider theme={getTheme('light')}> {/* Начальная тема может быть изменена через контекст */}
          <CssBaseline />
          <GlobalStyles
            styles={{
              'html, body, #root': {
                height: '100%',
                width: '100%',
                margin: 0,
                padding: 0,
              },
              body: {
                fontFamily: 'Roboto, sans-serif',
                backgroundColor: 'var(--background-default)', // Используется цвет из темы
              },
            }}
          />
          <Router>
            <App />
          </Router>
        </ThemeProvider>
      </AppProvider>
    </React.StrictMode>
  );
};

root.render(<RootComponent />);
