// frontend/src/pages/TelegramWebAppAuth.js

import React, { useEffect, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { telegramWebAppAuth } from '../services/api';
import { AppContext } from '../context/AppContext';
import EnergeticWavesBackground from '../components/EnergeticWavesBackground'; // Используем свой фон

const TelegramWebAppAuth = () => {
  const navigate = useNavigate();
  const { isTelegramWebApp, toggleTheme, fetchUserData, setNotification } = useContext(AppContext);

  useEffect(() => {
    if (!isTelegramWebApp) {
      navigate('/login');
      return;
    }

    const initData = window.Telegram.WebApp.initData;

    if (initData) {
      telegramWebAppAuth(initData)
        .then(response => {
          const { access_token, theme } = response.data;
          localStorage.setItem('access_token', access_token);
          toggleTheme(theme === 'auto' ? 'auto' : theme);
          fetchUserData();
          setNotification({ type: 'success', text: 'Успешная авторизация!' });
          navigate('/');
        })
        .catch(error => {
          console.error('Telegram Web App Auth Error:', error);
          setNotification({ type: 'error', text: 'Ошибка авторизации через Telegram.' });
          navigate('/login');
        });
    } else {
      navigate('/login');
    }
  }, [navigate, isTelegramWebApp, toggleTheme, fetchUserData, setNotification]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      position="relative"
      sx={{ textAlign: 'center', px: 2 }}
    >
      {/* Уникальный фон для страниц аутентификации */}
      <EnergeticWavesBackground />

      <Typography variant="h4" gutterBottom>
        Авторизация через Telegram...
      </Typography>
    </Box>
  );
};

export default TelegramWebAppAuth;
