import React, { useState, useEffect } from 'react';

const LogoMin = ({ width = 100, height = 'auto' }) => {
  const [hovered, setHovered] = useState(false);
  const [randomAnimate, setRandomAnimate] = useState(false);

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  // Генерация случайного времени между 30 и 90 секундами
  const getRandomDelay = () => Math.random() * (90000 - 30000) + 30000;

  // Генерация случайного времени между 2 и 5 секундами
  const getFirstDelay = () => Math.random() * (5000 - 2000) + 2000;

  useEffect(() => {
    const triggerRandomAnimation = () => {
      setRandomAnimate(true);

      // Держим логотип раскрытым 7 секунд, затем закрываем
      setTimeout(() => setRandomAnimate(false), 7000);
    };

    const scheduleNextAnimation = () => {
      const delay = getRandomDelay();
      setTimeout(() => {
        triggerRandomAnimation();
        scheduleNextAnimation();
      }, delay);
    };

    // Первая анимация с задержкой 2–5 секунд
    const firstDelay = getFirstDelay();
    setTimeout(() => {
      triggerRandomAnimation();
      scheduleNextAnimation();
    }, firstDelay);
  }, []);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 115.428 94.18"
      style={{ width, height }}
      className={`logo-min ${hovered || randomAnimate ? 'hovered' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <defs>
        <style>
          {`
            .cls-1 {
              fill: #f9f9f9;
              transition: transform 0.4s ease, opacity 0.4s ease;
            }

            /* Анимация вращения для первой буквы */
            .logo-min .original {
              transform-origin: 40px 47px; /* Центр вращения буквы */
              transform: rotate(0deg); /* Исходное положение */
              transition: transform 0.4s ease;
            }

            .logo-min.hovered .original {
              transform: rotate(90deg); /* Вращение на 90 градусов */
            }
          `}
        </style>
      </defs>
      <g>
        {/* Левый элемент */}
        <g className="original">
          <path
            className="cls-1"
            d="M81.05,4.57v12.49c-2.48,1.76-5.19,3.68-8.26,5.86v50.67c2.67,1.9,5.39,3.85,8.72,6.23-.19,3.59-.37,7.21-.64,12.3-20.53-8-39.71-15.46-58.87-22.95-3.92-1.53-7.81-3.14-11.68-4.79C2.62,61.08-.03,55.06,0,46.89c.03-8.05,3.67-13.21,10.78-15.93C33.6,22.26,56.5,13.77,81.05,4.57ZM57.15,68.44V27.21c-15.17,7.95-31.6,9.2-45.89,20.69,14.43,10.94,30.23,13.75,45.89,20.54Z"
          />
        </g>

        {/* Центральный элемент */}
        <path
          className="cls-1"
          d="M113.91,2.77v88.2c-3.81.22-7.39.42-12.28.71V2.77h12.28Z"
        />
      </g>
    </svg>
  );
};

export default LogoMin;