// frontend/src/components/Notification.js

import React, { useContext } from 'react';
import { Snackbar, Alert as MuiAlert } from '@mui/material';
import { AppContext } from '../context/AppContext';
import { useTheme } from '@mui/material/styles';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = () => {
  const { notification, setNotification } = useContext(AppContext);
  const theme = useTheme();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setNotification(null);
  };

  const severityMapping = {
    success: 'success',
    error: 'error',
    info: 'info',
    warning: 'warning',
  };

  return (
    <Snackbar
      open={!!notification}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      {notification && (
        <Alert
          onClose={handleClose}
          severity={severityMapping[notification.type] || 'info'}
          sx={{
            width: '100%',
            backgroundColor:
              notification.type === 'success'
                ? theme.palette.secondary.main
                : notification.type === 'info'
                ? theme.palette.primary.main
                : theme.palette.error.main,
            color: '#fff',
          }}
        >
          {notification.text}
        </Alert>
      )}
    </Snackbar>
  );
};

export default Notification;
