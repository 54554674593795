// frontend/src/App.js

import React, { useEffect, useContext, useMemo, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import TelegramLogin from './pages/TelegramLogin';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import TelegramWebAppAuth from './pages/TelegramWebAppAuth';
import { ThemeProvider, CssBaseline } from '@mui/material';
import ErrorBoundary from './components/ErrorBoundary';
import { AppContext } from './context/AppContext';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './components/Layout';
import Notification from './components/Notification';
import getTheme from './theme';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isTelegramWebApp, themeMode } = useContext(AppContext);
  
  const [currentTheme, setCurrentTheme] = useState('light');

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      if (['/login', '/telegram-webapp-auth'].includes(location.pathname)) {
        navigate('/');
      }
    } else {
      if (isTelegramWebApp && location.pathname !== '/telegram-webapp-auth') {
        navigate('/telegram-webapp-auth');
      } else if (!isTelegramWebApp && location.pathname !== '/login') {
        navigate('/login');
      }
    }
  }, [navigate, location, isTelegramWebApp]);

  // Функция для определения темы на основе времени суток
  const determineTheme = () => {
    const hour = new Date().getHours();
    if (hour >= 6 && hour < 18) {
      return 'light';
    }
    return 'dark';
  };

  // Установка темы при монтировании и при изменении themeMode
  useEffect(() => {
    if (themeMode === 'auto') {
      setCurrentTheme(determineTheme());

      // Определение времени до следующего переключения темы
      const now = new Date();
      let nextSwitch = new Date();

      if (currentTheme === 'light') {
        // Следующее переключение на темную тему в 18:00
        nextSwitch.setHours(18, 0, 0, 0);
      } else {
        // Следующее переключение на светлую тему в 6:00
        nextSwitch.setHours(6, 0, 0, 0);
      }

      if (nextSwitch <= now) {
        // Если время уже прошло, добавляем 12 часов
        nextSwitch.setHours(nextSwitch.getHours() + 12);
      }

      const timeout = nextSwitch.getTime() - now.getTime();

      const timer = setTimeout(() => {
        setCurrentTheme(determineTheme());
      }, timeout);

      return () => clearTimeout(timer);
    } else {
      setCurrentTheme(themeMode);
    }
  }, [themeMode]);

  const theme = useMemo(() => getTheme(currentTheme), [currentTheme]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        <Routes>
          {/* Публичные маршруты */}
          <Route path="/login" element={<TelegramLogin />} />
          <Route path="/telegram-webapp-auth" element={<TelegramWebAppAuth />} />

          {/* Защищённые маршруты с общей оболочкой */}
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <Layout>
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/settings" element={<Settings />} />
                    {/* Добавьте дополнительные защищённые маршруты здесь */}
                  </Routes>
                </Layout>
              </ProtectedRoute>
            }
          />
        </Routes>
        <Notification /> {/* Глобальные уведомления */}
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
