// frontend/src/pages/TelegramLogin.js

import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, Paper, Divider, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { telegramAuth } from '../services/api';
import { AppContext } from '../context/AppContext';
import EnergeticWavesBackground from '../components/EnergeticWavesBackground';
import Logo from '../components/Logo';
import messages from './messages';
import { styled, keyframes } from '@mui/material/styles';

// Определяем анимацию для мигающего курсора
const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

// Создаем стилизованный компонент для курсора
const BlinkingCursor = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: '2px',
  height: '1em',
  backgroundColor: theme.palette.primary.main,
  marginLeft: '4px',
  animation: `${blink} 1s step-start infinite`,
}));

const TelegramLogin = () => {
  const navigate = useNavigate();
  const { isTelegramWebApp, fetchUserData, setNotification } = useContext(AppContext);

  const [displayedText, setDisplayedText] = useState('');
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    if (charIndex < messages[currentMessageIndex].length) {
      const typingTimeout = setTimeout(() => {
        setDisplayedText(prev => prev + messages[currentMessageIndex][charIndex]);
        setCharIndex(prev => prev + 1);
      }, 100);

      return () => clearTimeout(typingTimeout);
    } else {
      const pauseTimeout = setTimeout(() => {
        setDisplayedText('');
        setCharIndex(0);
        setCurrentMessageIndex(prev => (prev + 1) % messages.length);
      }, 2000);

      return () => clearTimeout(pauseTimeout);
    }
  }, [charIndex, currentMessageIndex]);

  useEffect(() => {
    if (isTelegramWebApp) {
      navigate('/telegram-webapp-auth');
      return;
    }

    const token = localStorage.getItem('access_token');
    if (token) {
      navigate('/');
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?22';
    script.async = true;
    script.setAttribute('data-telegram-login', 'aiinsight_ru_bot');
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-userpic', 'true');
    script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    script.setAttribute('data-request-access', 'write');

    window.onTelegramAuth = async (user) => {
      try {
        const response = await telegramAuth(user);
        const { access_token } = response.data;
        localStorage.setItem('access_token', access_token);
        fetchUserData();
        setNotification({ type: 'success', text: 'Успешный вход!' });
        navigate('/');
      } catch (error) {
        console.error('Telegram Auth Error:', error);
        setNotification({ type: 'error', text: 'Ошибка при входе через Telegram.' });
      }
    };

    const container = document.getElementById('telegram-login-button');
    container?.appendChild(script);

    return () => {
      container?.removeChild(script);
      delete window.onTelegramAuth;
    };
  }, [navigate, isTelegramWebApp, fetchUserData, setNotification]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      position="relative"
      sx={{ textAlign: 'center', px: 2 }}
    >
      <EnergeticWavesBackground />

      <Box
        sx={{
          position: 'relative',
          width: '100%',
          maxWidth: { xs: '100%', sm: 500, md: 900 },
        }}
      >
        <Paper
          elevation={6}
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            gap: { xs: 2, md: 0 },
            borderRadius: '16px',
            overflow: 'hidden',
            position: 'relative',
            zIndex: 2,
          }}
        >
          <Box
            flex={1}
            sx={{
              textAlign: { xs: 'center', md: 'left' },
              pr: { md: 4 },
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                lineHeight: 1.2,
                fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
              }}
            >
              Инсайты из океанов данных о конкурентах и рынке
            </Typography>
            <Box
              sx={{
                minHeight: '4rem',
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-start' },
                alignItems: 'flex-start',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.main',
                  fontSize: { xs: '1.25rem', sm: '1.25rem' },
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                {displayedText}
                <BlinkingCursor />
              </Typography>
            </Box>
          </Box>

          <Divider
            orientation="vertical"
            flexItem
            sx={{
              display: { xs: 'none', md: 'block' },
              mx: 3,
            }}
          />
          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              display: { xs: 'block', md: 'none' },
              my: 2,
              width: '80%',
            }}
          />

          <Box
            flex={1}
            sx={{
              textAlign: 'center',
            }}
          >
            <Box id="telegram-login-button" sx={{ marginBottom: '20px' }}></Box>
            <Typography
              variant="caption"
              sx={{
                fontSize: { xs: '0.7rem', sm: '0.8rem' },
              }}
            >
              Авторизуясь, вы соглашаетесь с нашими{' '}
              <Link href="/terms-of-use" underline="hover">
                Пользовательским соглашением
              </Link>
              ,{' '}
              <Link href="/privacy-policy" underline="hover">
                Политикой конфиденциальности
              </Link>{' '}
              и{' '}
              <Link href="/refund-policy" underline="hover">
                Политикой оплаты и возврата
              </Link>.
            </Typography>
          </Box>
        </Paper>

        <Box
          sx={{
            position: 'relative',
            backgroundColor: 'black',
            color: 'white',
            padding: '20px 0px',
            textAlign: 'center',
            borderRadius: '0 0 16px 16px',
            mt: '-10px',
            zIndex: 1,
          }}
        >
          <Typography
            variant="caption"
            sx={{
              fontSize: { xs: '0.8rem', sm: '0.9rem' },
              lineHeight: 1.6,
            }}
          >
            ❗ Платформа находится в закрытом pre-alpha тесте.
            <br />
            ❗ Регистрация потребует уникальный ключ. Если у вас его нет - зарегистрироваться не получится 😢
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 20,
          left: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center',
        }}
      >
        <Logo width="150px" />
      </Box>
    </Box>
  );
};

export default TelegramLogin;
