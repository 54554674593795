// frontend/src/services/api.js

import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || '/api/',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Добавление токена в заголовки запросов
API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Обработка ошибок
API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('access_token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// Функции для взаимодействия с API
export const telegramAuth = (userData) => API.post('users/telegram_auth/', null, { params: userData });

export const telegramWebAppAuth = (initData) => API.post('users/telegram_webapp_auth/', { init_data: initData });

export const getDashboard = () => API.get('users/dashboard/');

export const updateSettings = (settings) => API.post('users/settings/', settings);

export const verifyPhone = () => API.post('users/verify_phone/');

// Функция для обновления номера телефона
export const updateUserPhone = (phoneNumber) => API.post('users/update_phone/', { phone_number: phoneNumber });
