// frontend/src/components/ErrorBoundary.js

import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error Boundary Caught:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Используем хук useTheme внутри класса невозможно, поэтому применяем альтернативный подход
      // Например, можно передать тему через props или использовать другой метод
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          px={2}
          sx={{ textAlign: 'center', backgroundColor: 'background.paper' }}
        >
          <Typography variant="h4" color="error" gutterBottom>
            Что-то пошло не так.
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {this.state.error?.toString() || 'Неизвестная ошибка.'}
          </Typography>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
