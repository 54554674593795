import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Grid,
  Link,
  CircularProgress,
} from '@mui/material';
import { updateSettings, verifyPhone } from '../services/api';
import { AppContext } from '../context/AppContext';

const formatPhoneNumber = (phone) => {
  if (!phone) return '';
  const cleaned = ('' + phone).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1,4})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
  }
  return phone;
};

const Settings = () => {
  const { isTelegramWebApp, toggleTheme, user, fetchUserData, setNotification } = useContext(AppContext);
  
  // Инициализируем formData как null, пока данные пользователя не загружены
  const [formData, setFormData] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [showBotLink, setShowBotLink] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState('general');
  const [emailError, setEmailError] = useState('');

  // Обновляем formData, когда user становится доступным
  useEffect(() => {
    if (user) {
      setFormData({
        theme: user.theme || 'auto',
        email: user.email || '',
        organization: user.organization || '',
        position: user.position || '',
        productWebsite: user.productWebsite || '',
        productDescription: user.productDescription || '',
      });
      setHasChanges(false); // Сбрасываем флаг изменений при загрузке данных
    }
  }, [user]);

  if (!user || !formData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <CircularProgress size={60} color="secondary" />
      </Box>
    );
  }

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleInputChange = (field, value) => {
    if (field === 'email') {
      if (!validateEmail(value)) {
        setEmailError('Некорректный email адрес');
      } else {
        setEmailError('');
      }
    }

    setFormData((prev) => {
      const updated = { ...prev, [field]: value };
      // Сравниваем обновленные данные с данными пользователя
      const hasChanges =
        updated.theme !== (user.theme || 'auto') ||
        updated.email !== (user.email || '') ||
        updated.organization !== (user.organization || '') ||
        updated.position !== (user.position || '') ||
        updated.productWebsite !== (user.productWebsite || '') ||
        updated.productDescription !== (user.productDescription || '');
      setHasChanges(hasChanges);
      return updated;
    });
  };

  const handleVerifyPhone = async () => {
    try {
      const response = await verifyPhone();
      const { message: msg, bot_link } = response.data;
      setVerificationMessage(bot_link ? `${msg}. Вы можете перейти в ` : msg);
      setShowBotLink(!!bot_link);
    } catch (error) {
      console.error('Verify Phone Error:', error);
      setVerificationMessage('Ошибка при отправке сообщения. Попробуйте позже.');
      setShowBotLink(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailError) {
      setNotification({ type: 'error', text: 'Пожалуйста, исправьте ошибки в форме.' });
      return;
    }
    try {
      const response = await updateSettings(formData);
      setNotification({ type: 'success', text: 'Настройки успешно обновлены.' });
      toggleTheme(response.data.theme === 'auto' ? 'auto' : response.data.theme);
      fetchUserData();
      setHasChanges(false);
    } catch (error) {
      console.error('Update Settings Error:', error);
      setNotification({ type: 'error', text: 'Ошибка при обновлении настроек.' });
    }
  };

  const renderGeneralSettings = () => (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Общие настройки
        </Typography>
        <Box sx={{ mb: 3 }}>
          <Typography variant="body1">
            Номер телефона: {user && user.phone ? formatPhoneNumber(user.phone) : 'Не указан'}
          </Typography>
          <Typography
            variant="body2"
            color={user?.is_phone_verified ? 'success.main' : 'error.main'}
            sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
          >
            {user?.is_phone_verified ? (
              <>
                Подтвержден <span role="img" aria-label="verified" style={{ marginLeft: 4 }}>✅</span>
              </>
            ) : (
              <>
                Не подтвержден <span role="img" aria-label="not-verified" style={{ marginLeft: 4 }}>❌</span>
              </>
            )}
          </Typography>
          {!user?.is_phone_verified && (
            isTelegramWebApp ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.Telegram.WebApp.requestContact()}
                sx={{
                  transition: 'background-color 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#1BF2B5',
                  },
                }}
              >
                Подтвердить
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleVerifyPhone}
                sx={{ mt: 2 }}
              >
                Подтвердить
              </Button>
            )
          )}
          {verificationMessage && (
            <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
              {verificationMessage}
              {showBotLink && (
                <Link href="https://t.me/aiinsight_ru_bot" target="_blank" rel="noopener">
                  бота
                </Link>
              )}
            </Typography>
          )}
        </Box>
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            value={formData.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
            label="Электронная почта"
            placeholder="Введите email"
            variant="outlined"
            error={!!emailError}
            helperText={emailError}
          />
        </Box>
        <Box>
          <FormControl fullWidth>
            <InputLabel id="theme-label">Тема приложения</InputLabel>
            <Select
              labelId="theme-label"
              value={formData.theme}
              label="Тема приложения"
              onChange={(e) => handleInputChange('theme', e.target.value)}
            >
              <MenuItem value="light">Светлая</MenuItem>
              <MenuItem value="dark">Тёмная</MenuItem>
              <MenuItem value="auto">Автоматическая</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </CardContent>
    </Card>
  );

  const renderOrganizationSettings = () => (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Данные основной организации
        </Typography>
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            value={formData.organization}
            onChange={(e) => handleInputChange('organization', e.target.value)}
            label="Название организации"
            placeholder="Введите название организации"
            variant="outlined"
          />
        </Box>
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            value={formData.position}
            onChange={(e) => handleInputChange('position', e.target.value)}
            label="Должность"
            placeholder="Введите должность"
            variant="outlined"
          />
        </Box>
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            value={formData.productWebsite}
            onChange={(e) => handleInputChange('productWebsite', e.target.value)}
            label="Сайт продукта"
            placeholder="Введите адрес сайта"
            variant="outlined"
          />
        </Box>
        <Box>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={formData.productDescription}
            onChange={(e) => handleInputChange('productDescription', e.target.value)}
            label="Описание продукта"
            placeholder="Введите описание продукта"
            variant="outlined"
          />
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <List component="nav">
            <ListItem disablePadding>
              <ListItemButton
                selected={selectedBlock === 'general'}
                onClick={() => setSelectedBlock('general')}
              >
                <ListItemText primary="Общие настройки" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                selected={selectedBlock === 'organization'}
                onClick={() => setSelectedBlock('organization')}
              >
                <ListItemText primary="Данные организации" />
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={8}>
          {selectedBlock === 'general' && renderGeneralSettings()}
          {selectedBlock === 'organization' && renderOrganizationSettings()}
          {hasChanges && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{
                  minWidth: 200,
                  transition: 'background-color 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#1BF2B5',
                  },
                }}
              >
                Сохранить
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Settings;
