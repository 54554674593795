// frontend/src/components/ProtectedRoute.js

import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AppContext } from '../context/AppContext';

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const { isTelegramWebApp } = useContext(AppContext);
  const token = localStorage.getItem('access_token');

  if (!token) {
    const redirectPath = isTelegramWebApp ? '/telegram-webapp-auth' : '/login';
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;