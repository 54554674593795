// frontend/src/components/Logo.js

import React, { useState, useEffect } from 'react';

const Logo = ({ width = 200, height = 30, minimized = false }) => {
  const [hovered, setHovered] = useState(false);
  const [randomAnimate, setRandomAnimate] = useState(false);

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  // Генерация случайного времени между 30 и 90 секундами
  const getRandomDelay = () => Math.random() * (90000 - 30000) + 30000;

  // Генерация случайного времени между 2 и 5 секундами
  const getFirstDelay = () => Math.random() * (5000 - 2000) + 2000;

  useEffect(() => {
    const triggerRandomAnimation = () => {
      setRandomAnimate(true);

      // Держим логотип раскрытым 7 секунд, затем закрываем
      setTimeout(() => setRandomAnimate(false), 7000);
    };

    const scheduleNextAnimation = () => {
      const delay = getRandomDelay();
      setTimeout(() => {
        triggerRandomAnimation();
        scheduleNextAnimation();
      }, delay);
    };

    // Первая анимация с задержкой 2–5 секунд
    const firstDelay = getFirstDelay();
    const initialTimeout = setTimeout(() => {
      triggerRandomAnimation();
      scheduleNextAnimation();
    }, firstDelay);

    return () => clearTimeout(initialTimeout);
  }, []);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800 94.18"
      width={width}
      height={height}
      className={`logo ${hovered || randomAnimate ? 'hovered' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <defs>
        <style>
          {`
            .cls-1 {
              fill: #f9f9f9;
              transition: transform 0.4s ease, opacity 0.4s ease;
            }

            /* Вращение буквы */
            .logo .original {
              transform-origin: 40px 47px; /* Центр вращения буквы */
              transform: rotate(0deg); /* Исходное положение */
              transition: transform 0.4s ease;
            }

            .logo.hovered .original {
              transform: rotate(90deg); /* Вращение на 90 градусов */
            }

            /* Новая буква и точка появляются */
            .logo .new, .logo .dot {
              opacity: 0;
              transform: translateX(-140px);
              transition: transform 0.4s ease, opacity 0.4s ease;
            }

            .logo.hovered .new, .logo.hovered .dot {
              opacity: 1;
              transform: translateX(0);
            }

            /* Сдвигаемые элементы */
            .logo .shift {
              transform: translateX(0);
              transition: transform 0.4s ease;
            }

            .logo.hovered .shift {
              transform: translateX(140px);
            }
          `}
        </style>
      </defs>
      <g>
        {/* Левый элемент */}
        <g className="original">
          <path
            className="cls-1"
            d="M81.05,4.57v12.49c-2.48,1.76-5.19,3.68-8.26,5.86v50.67c2.67,1.9,5.39,3.85,8.72,6.23-.19,3.59-.37,7.21-.64,12.3-20.53-8-39.71-15.46-58.87-22.95-3.92-1.53-7.81-3.14-11.68-4.79C2.62,61.08-.03,55.06,0,46.89c.03-8.05,3.67-13.21,10.78-15.93C33.6,22.26,56.5,13.77,81.05,4.57ZM57.15,68.44V27.21c-15.17,7.95-31.6,9.2-45.89,20.69,14.43,10.94,30.23,13.75,45.89,20.54Z"
          />
        </g>

        {/* Центральный элемент */}
        <path
          className="cls-1"
          d="M113.91,2.77v88.2c-3.81.22-7.39.42-12.28.71V2.77h12.28Z"
        />

        {/* Точка перед новой буквой */}
        {!minimized && (
          <circle
            className="cls-1 dot"
            cx="140" /* Положение по горизонтали */
            cy="80" /* Положение по вертикали — внизу буквы */
            r="10" /* Радиус точки */
          />
        )}

        {/* Новая буква */}
        {!minimized && (
          <path
            className="cls-1 new"
            d="M250,3.07c7.14,5.66,8.85,12.25,8.74,20.05-.3,22.19-.11,44.39-.11,67.84-4.77.21-8.7.39-14.59.65V16.97c-9.91-4.04-12.69,1.62-15.3,7.27-8.49,18.42-16.8,36.93-25.35,55.32-6.31,13.57-18.97,18.52-31.06,11.29-4.07-2.43-8.79-7.89-8.93-12.1-.83-25.2-.41-50.44-.41-76.12h14.1v75.19h11.66c7.51-16.21,14.93-32.16,22.28-48.14,1.76-3.83,3.11-7.85,4.97-11.62,8.54-17.27,14.99-20.06,33.99-14.99Z"
          />
        )}

        {/* Сдвигаемые элементы */}
        {!minimized && (
          <g className="shift">
            <path
              className="cls-1"
              d="M215.21,90.96h-13.96v-35.9h-48.07v36h-13.75V2.71h13.23v36.55h47.93V3.14h14.63v87.83Z"
            />
            <path
              className="cls-1"
              d="M310.39,77.84v12.86c-16.08,0-31.78,1.1-47.24-.37-11.42-1.09-19.28-9.57-22.85-20.44-4.77-14.53-5.15-29.57-.4-44.16,4.46-13.68,14.75-21.72,28.93-22.78,13.68-1.02,27.5-.22,42.17-.22v14.12c-10.33,0-20.19.51-29.98-.11-21.41-1.35-31.69,8.49-29.47,35.58,1.88,22.94,11.88,25.68,30.31,25.53,9.13-.08,18.27-.01,28.52-.01Z"
            />
            <path
              className="cls-1"
              d="M421.35,91.25h-13.3c-2.6-5.48-5.33-11.25-8.34-17.59h-51.51c-2.73,6-5.24,11.51-7.93,17.41h-15.17c5.03-13.17,9.57-25.28,14.29-37.32,5.17-13.17,10.19-26.4,15.87-39.35,5.82-13.27,17.14-17.82,28.75-11.75,3.16,1.65,6.6,4.59,7.86,7.75,10.28,25.97,20.09,52.13,29.98,78.25.21.57-.25,1.39-.5,2.59ZM393.51,59.05c-4.84-23.9-12.66-40.06-22.38-43.67-5.61,14.09-11.25,28.24-17.4,43.67h39.79Z"
            />
            <path
              className="cls-1"
              d="M462.11,2.9h16.21c-5.24,11.67-10,22.26-14.76,32.85-.48-.07-.96-.14-1.45-.21V2.9Z"
            />
            <path
              className="cls-1"
              d="M520.94,3.07c7.14,5.66,8.85,12.25,8.74,20.05-.3,22.19-.11,44.39-.11,67.84-4.77.21-8.7.39-14.59.65V16.97c-9.91-4.04-12.69,1.62-15.3,7.27-8.49,18.42-16.8,36.93-25.35,55.32-6.31,13.57-18.97,18.52-31.06,11.29-4.07-2.43-8.79-7.89-8.93-12.1-.83-25.2-.41-50.44-.41-76.12h14.1v75.19h11.66c7.51-16.21,14.93-32.16,22.28-48.14,1.76-3.83,3.11-7.85,4.97-11.62,8.54-17.27,14.99-20.06,33.99-14.99Z"
            />
            <path
              className="cls-1"
              d="M572.24,16.57h-26.2V2.62h65.67v13.53c-7.87.38-15.74.76-25.2,1.22v73.63h-14.26V16.57Z"
            />
          </g>
        )}
      </g>
    </svg>
  );
};

export default Logo;
