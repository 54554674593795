// frontend/src/pages/Dashboard.js

import React, { useEffect, useContext } from 'react';
import {
  Typography,
  Container,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../context/AppContext';

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, fetchUserData } = useContext(AppContext);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const researchData = [
    { id: 1, name: 'Исследование рынка X', date: '2024-01-15' },
    { id: 2, name: 'Анализ конкурентов Y', date: '2024-02-20' },
    { id: 3, name: 'Тренды индустрии Z', date: '2024-03-10' },
  ];

  if (!user) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <CircularProgress size={60} color="secondary" />
      </Box>
    );
  }

  return (
    <Container sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        {/* Начать новое исследование */}
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 2,
              backgroundColor: 'primary.main',
              color: '#ffffff',
              boxShadow: 4,
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: 5,
              },
            }}
          >
            <Typography variant="h6" gutterBottom>
              Начать новое исследование
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate('/launch-research')}
              sx={{
                mt: 1,
                backgroundColor: 'secondary.main',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#17d1a4',
                },
              }}
            >
              Начать
            </Button>
          </Card>
        </Grid>

        {/* Проведенные исследования */}
        <Grid item xs={12} md={8}>
          <Typography variant="h5" gutterBottom>
            Проведенные исследования
          </Typography>
          <Grid container spacing={1}>
            {researchData.map((research) => (
              <Grid item xs={12} sm={6} key={research.id}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: 3,
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: 4,
                    },
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {research.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Дата: {research.date}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ mt: 'auto' }}>
                    <Button
                      size="small"
                      onClick={() => navigate(`/research/${research.id}`)}
                      color="primary"
                    >
                      Подробнее
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
